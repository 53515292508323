<template>
  <b-container fluid>
    <b-row v-if="loading">
      <LoadingSpinner :loading="loading" />
    </b-row>
    <b-row v-else>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ count }} Countries</h4>
          </template>
          <template v-slot:headerAction>
            <div class="input-with-icon">
    <span class="icon is-small is-left">
      <i class="fas fa-search"></i>
    </span>
            <input type="search" class="form-control mr-5" v-model="searchQuery" @input="handleSearch"
              placeholder="Search">
              </div>
            <b-button variant="primary" @click="openModal" style="border-radius: 5px">Add Country</b-button>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12" v-if="!rows.length" class="table-responsive">
                <h5 class="text-center mt-4">{{ response_message }}</h5>
              </b-col>
              <b-col md="12" v-else class="table-responsive">
                <b-table bordered hover :items="rows" :fields="columns" :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc">
                  <template v-slot:cell(serialNumber)="data">
                    {{ (currentPage - 1) * itemsPerPage + data.index + 1 }}
                  </template>
                  <template v-slot:cell(action)="data">
                    <div class="text-left">
                      <button fdprocessedid="epulve" title="Scan" type="button"
                        class="btn btn btn-success btn-secondary btn-sm"
                        style="padding: 4px !important; border-radius: 3px !important; margin-right: 8px; margin-top: 5px;"><i
                          class="fas fa-search " style="margin-left: 5px !important;"
                          @click="handleButtonClick(data.item.public_id)"></i></button>
                      <b-button :disabled="data.item.is_start === true" variant="info" size="sm"
                        @click="edit(data.item)"
                        style="padding: 4px !important; border-radius: 3px !important; margin-right: 8px; margin-top:5px"
                        title="Edit">
                        <i class="fas fa-pencil-alt" style="margin-left: 5px !important;"></i>
                        <!-- Edit font icon -->
                      </b-button>
                      <!-- <button title="Stop" class="btn btn-success mb-1" fdprocessedid="epulve"><i class="fas fa-stop"></i></button> -->
                      <b-button :disabled="data.item.is_start === true" variant="danger" size="sm"
                        @click="remove(data.item.public_id)"
                        style="padding: 4px !important; border-radius: 3px !important; margin-right: 8px; margin-top:5px"
                        title="Delete">
                        <i class="fas fa-trash-alt" style="margin-left: 5px !important;"></i>
                        <!-- Delete font icon -->
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="itemsPerPage" @change="onPageChange"
            align="right" />
        </iq-card>
      </b-col>
    </b-row>
    <!-- Add/Edit Modal -->
    <b-modal v-model="modalOpen" :title="editMode ? 'Edit Country' : 'Add New Country'" ok-title="Save" @ok="submitForm"
      @hide="checkModalClose" no-close-on-backdrop no-close-on-esc :disabled="isSubmitting">
      <b-form @submit.stop.prevent="submitForm">
        <b-form-group label="Name" label-for="nameInput" :state="nameState" invalid-feedback="Name is required">
          <b-form-input id="nameInput" :maxlength="20" @keydown="preventLeadingSpace($event, 'name')"
            v-model="newCity.name" style="border-color:lightgray; height:38px;" required></b-form-input>
          <small v-if="!nameState && nameError" class="text-danger">{{ nameError }}</small>
        </b-form-group>
        <b-form-group label="City" label-for="cityInput" :state="cityState" invalid-feedback="City is required">
          <b-form-input id="cityInput" :maxlength="20" @keydown="preventLeadingSpace($event, 'city')"
            v-model="newCity.city" style="border-color:lightgray; height:38px;" required></b-form-input>
          <small v-if="!cityState && cityError" class="text-danger">{{ cityError }}</small>
        </b-form-group>
        <b-form-group label="Code" label-for="codeInput" :state="codeState" invalid-feedback="Code is required">
          <b-form-input id="codeInput" :maxlength="5" v-model="newCity.code"
            @keydown="preventLeadingSpace($event, 'code')" style="border-color:lightgray; height:38px;"
            required></b-form-input>
          <small v-if="!codeState && codeError" class="text-danger">{{ codeError }}</small>
        </b-form-group>
        <b-form-group label="Sensor IP" label-for="sensorIpInput" :state="sensorIpState"
          invalid-feedback="Sensor IP is required">
          <b-form-input id="sensorIpInput" :maxlength="20" v-model="newCity.sensor_ip"
            @keydown="preventLeadingSpace($event, 'sensor_ip')" style="border-color:lightgray; height:38px;"
            required></b-form-input>
        </b-form-group>
        <b-form-group label="Type" label-for="typeIpInput" :state="typeState" invalid-feedback="Type is required">
          <b-form-select id="typeIpInput" v-model="newCity.type" :options="types" required></b-form-select>
        </b-form-group>
      </b-form>
    </b-modal>
  </b-container>
</template>
<script>
import axios from "axios";
import constant, { decryptToken, response_message, toastMessage } from "@/config/constant";
import LoadingSpinner from "@/config/Pageloader.vue";
import { core } from "../../../config/pluginInit";
import { BPagination } from "bootstrap-vue";
import Swal from "sweetalert2";

export default {
  name: "Country",
  components: {
    BPagination,
    LoadingSpinner
  },
  data() {
    return {
      isSubmitting: false, // Define isSubmitting in the data object
      columns: [
        { label: 'S. No.', key: 'serialNumber', class: 'text-left' },
        { label: 'Name', key: 'name', class: 'text-left', sortable: true },
        { label: 'City', key: 'city', class: 'text-left', sortable: true },
        { label: 'Code', key: 'code', class: 'text-left', sortable: true },
        { label: 'Type', key: 'type', class: 'text-left', sortable: true },
        { label: 'Sensor Ip', key: 'sensor_ip', class: 'text-left', sortable: true },
        { label: 'Action', key: 'action', class: 'text-center' }
      ],
      sortBy: '',
      sortDesc: false,
      searchQuery: "",
      response_message: response_message,
      preventModalClose: false,
      nameState: null,
      cityState: null,
      codeState: null,
      sensorIpState: null,
      typeState: null,
      nameError: '',
      cityError: '',
      codeError: '',
      currentPage: 1,
      itemsPerPage: 20,
      totalRows: 0,
      message: "",
      icon: "",
      count: 0,
      rows: [], // Initialize the rows array to be empty initially
      modalOpen: false,
      editMode: false,
      newCity: {
        name: '',
        city: '',
        sensor_ip: '',
        type: '',
        code: ''
      },
      types: [
        { text: 'Http', value: 'http' },
        { text: 'Https', value: 'https' }
      ], // Initialize the sensor IPs array
      loading: true, // Add a loading state
    };
  },
  computed: {
    isFormValid() {
      return (
        this.nameState &&
        this.cityState &&
        this.codeState &&
        this.sensorIpState &&
        this.typeState
      );
    },
  },
  methods: {
    preventLeadingSpace(event, key) {
      // First letter space not allowed
      if (!this.newCity) {
        this.newCity = {
          name: "",
          city: "",
          sensor_ip: "",
          code: "",
        };
      }

      if (event.target.selectionStart === 0 && event.keyCode === 32) {
        event.preventDefault();
      }
    },
    handleSearch() {
      // Call fetchData with the searchQuery
      this.fetchData(this.currentPage, this.searchQuery);
      this.loading = true;
    },

    cancelForm() {
      this.modalOpen = false;
      this.clearForm(); // Optionally clear the form when canceling
    },

    async fetchData(pageNumber, searchQuery = "") {
      try {
        const token = localStorage.getItem('token');
        const key = 'enc'; // Fetch encrypted token
        const decryptedToken = decryptToken(token, key); // Decrypt token

        const response = await axios.get(constant.API_BASE_URL + 'domain/countries/', {
          params: {
            page: pageNumber,
            search: searchQuery,
            ordering: "-created_at"
          },
          headers: {
            'Authorization': 'Token ' + decryptedToken
          }
        });

        this.rows = response.data.data;
        this.totalRows = response.data.count;
        this.count = response.data.count; //for display country count
        this.loading = false;
      } catch (error) {
        this.rows = [];
        this.loading = false;
      }
    },
    onPageChange(pageNumber) {
      this.currentPage = pageNumber;
      this.fetchData(pageNumber, this.searchQuery); // Fetch data for the new page
    },

    async submitForm() {
      try {
        const isNumericName = /^\d+$/.test(this.newCity.name);
        const isNumericCity = /^\d+$/.test(this.newCity.city);
        const isNumericCode = /^\d+$/.test(this.newCity.code);
        if (isNumericName) {
          // Display error message if name contains only numeric characters
          this.nameError = "Name should not contain only numeric characters.";
          this.nameState = false;
          // Prevent modal from closing
          this.preventModalClose = true;
          return; // Stop execution
        }
        else {
          this.nameError = '';
        }
        if (isNumericCity) {
          // Display error message if name contains only numeric characters
          this.cityError = "City should not contain only numeric characters.";
          this.cityState = false;
          // Prevent modal from closing
          this.preventModalClose = true;
          return; // Stop execution
        }
        else {
          this.cityError = '';
        }
        if (isNumericCode) {
          // Display error message if name contains only numeric characters
          this.codeError = "Code should not contain only numeric characters.";
          this.codeState = false;

          // Prevent modal from closing
          this.preventModalClose = true;
          return; // Stop execution
        } else {
          this.codeError = '';
        }
        this.nameState = this.newCity.name ? true : false;
        this.cityState = this.newCity.city ? true : false;
        this.codeState = this.newCity.code ? true : false;
        this.sensorIpState = this.newCity.sensor_ip ? true : false;
        this.typeState = this.newCity.type ? true : false;
        this.nameState = this.newCity.name.trim() !== '';
        // Check if the form is valid
        const isFormValid = this.nameState && this.cityState && this.codeState && this.sensorIpState && this.typeState;
        if (isFormValid) {
          this.isSubmitting = true; // Enable the submit button
          const token = localStorage.getItem('token');
          const key = 'enc'; // Fetch encrypted token
          const decryptedToken = decryptToken(token, key); // Decrypt token
          const { name, code, city, sensor_ip, type } = this.newCity;
          const requestData = {
            name: name,
            code: code,
            city: city,
            type: type,
            sensor_ip: sensor_ip
          };
          // Set the token in the request headers
          const headers = {
            'Authorization': 'Token ' + decryptedToken
          };

          let response;
          if (this.editMode) {
            // Edit mode
            response = await axios.put(constant.API_BASE_URL + 'domain/countries/' + this.newCity.public_id + '/', requestData, { headers });
          } else {
            // Add mode
            response = await axios.post(constant.API_BASE_URL + 'domain/countries/', requestData, { headers });
          }
          this.message = response.data.message
          this.icon = "success"
          // Call the toastMessage function to display a toast notification
          toastMessage(this.icon, this.message);
          await this.fetchData();
          this.modalOpen = false;
          this.clearForm();
        }
        else {
          this.preventModalClose = true;
          return false;
        }
      } catch (error) {
        if (error.response.data['name']) {
          this.message = error.response.data['name']
        }
        else if (error.response.data['code']) {
          this.message = error.response.data['code']
        }
        else if (error.response.data['sensor_ip']) {
          this.message = error.response.data['sensor_ip']
        }
        else if (error.response.data['city']) {
          this.message = error.response.data['city']
        }
        else {
          this.message = error.response.data['sensor_ip']
        }
        this.icon = "error"
        toastMessage(this.icon, this.message);
      }
    },
    checkModalClose(event) {
      if (this.preventModalClose) {
        event.preventDefault(); // Prevent the modal from closing
        this.preventModalClose = false; // Reset the flag
      }
    },
    openModal() {
      this.modalOpen = true;
      this.editMode = false;
      this.clearForm();
      if (this.types.length > 0) {
        this.newCity.type = this.types[0].value;
      }
    },
    edit(item) {
      this.newCity = { ...item };
      this.modalOpen = true;
      this.editMode = true;
    },
    async remove(public_id) {
      try {
        let self = this;
        Swal.fire({
          toast: true,
          position: "top",
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            const token = localStorage.getItem('token');
            const key = 'enc';
            const decryptedToken = decryptToken(token, key);
            axios.delete(constant.API_BASE_URL + 'domain/countries/' + public_id + '/', {
              headers: {
                'Authorization': 'Token ' + decryptedToken
              }
            }
            )
              .then(response => {
                this.message = response.data.message
                // Set the icon to "success" for successful response
                this.icon = "success"
                // Call the toastMessage function to display a toast notification
                toastMessage(this.icon, this.message);
                self.fetchData();
                // Do something with the response data if needed
              })
          }
        });
      } catch (error) {
        this.message = "Error deleting country"
        this.icon = "error"
        // Call the toastMessage function to display a toast notification
        toastMessage(this.icon, this.message);
        console.error('Error deleting city:', error);
      }
    },
    cancelForm() {
      this.modalOpen = false;
      this.clearForm(); // Optionally clear the form when canceling
    },
    checkModalClose(event) {
      if (this.preventModalClose) {
        event.preventDefault(); // Prevent the modal from closing
        this.preventModalClose = false; // Reset the flag
      }
    },
    clearForm() {
      this.newCity = {
        name: '',
        city: '',
        sensor_ip: '',
        type: '',
        code: ''
      };
    }

  },
  mounted() {
    if (localStorage.getItem("superadmin") == "false") {
      window.location.href = "/webmonitoring";
    }
    this.fetchData(this.currentPage);
    core.index();
  }
};
</script>
<style scoped>
input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
}

label {
  cursor: pointer;
  text-indent: -9999px;
  width: 60px;
  height: 30px;
  background: grey;
  display: block;
  border-radius: 30px;
  position: relative;
}

label:after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 30px;
  transition: 0.3s;
}

input:checked+label {
  background: #bada55;

}

input:checked+label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

label:active:after {
  width: 100px;
}
</style>
