<!-- Pageloader.vue -->
<template>
   <b-container fluid>
  <b-row v-if="loading">
    <b-col md="12" class="d-flex justify-content-center align-items-center" style="height: 20vh;">
      <b-spinner label="Loading..." class="mr-2"></b-spinner>
      <strong>Loading...</strong>
    </b-col>
  </b-row>
</b-container>
</template>

<script>
export default {
  name: "LoadingSpinner", // Use the name that will be used to register the component
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style scoped>

</style>
